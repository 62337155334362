import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Utils } from '../../../utils';
import { appConfig } from '../../../config/app.config';
import { Order } from '../models/order.model';
import { HttpClient } from '@angular/common/http';
import { OrderDetail } from '../models/order-detail.model';
import { apiConfig } from '../../../config/api.config';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class ProductService {

  constructor(
    private http: HttpClient
  ) {
  }

  getLastOrder(): Order {
    return Utils.getFromStore<Order>( appConfig.keys.lastOrder );
  }

  getHistory(): OrderDetail[] {
    return Utils.getFromStore<OrderDetail[]>( appConfig.keys.orderedProducts ) || [];
  }

  loadAndCacheAllProducts(): void {
    this.http.get<Product[]>(`${apiConfig.baseUrl}/api/product/GetSelectListItems`)
      .subscribe((res) => {
        Utils.saveToStore(appConfig.keys.products, res || []);
      });
  }

  searchProducts(key: string, limit: number = 5): Product[] {
    const products = Utils.getFromStore<Product[]>(appConfig.keys.products)
      .filter((product) => product.parentId || product.isSingle);
    return products.filter(item => {
      return item.name.toLowerCase().includes(key.toLowerCase()) || item.autoGenCode.toLowerCase().includes(key.toLowerCase());
    }).splice(0, limit - 1);
  }

}
