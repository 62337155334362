import { Pipe, PipeTransform } from '@angular/core';
import { apiConfig } from '../../config/api.config';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: string): string {
    return value ? `${apiConfig.fileUrl}${value}` : 'https://via.placeholder.com/200';
  }
}
