import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SpotlightService } from './spotlight.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SpotlightResult } from './models/spotlight-result.model';
import { Product } from '../../../screens/terminal/models/product.model';
import { Customer } from '../../../screens/payment/models/customer.model';


@Component( {
  selector: 'app-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: [ './spotlight.component.scss' ]
} )

export class SpotlightComponent implements OnInit, OnDestroy {
  items: Observable<SpotlightResult[]>;

  @Input() endpoint: string;
  @Output() result: EventEmitter<Product | Customer> = new EventEmitter<Product | Customer>();
  @Input() hiddenSearch: BehaviorSubject<boolean>;

  focusedIndex: number = 0;
  @Input() closeAfterSelction: boolean;

  @ViewChild('searchBox') searchBox;
  @Input() width: string = 'w-full md:w-9/12 lg:w-1/2';

  constructor(
    private inputSelect: SpotlightService,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.hiddenSearch = this.inputSelect.isHidden;
    this.inputSelect.listenKeyPress(this.el);
  }

  onSearch(key: any): void {
    const searchKey: string = key.target.value;
    this.items = this.inputSelect.search<SpotlightResult>(searchKey, this.endpoint);
  }

  onSelect( item: any ): void {
    this.result.emit(item);
    this.searchBox.nativeElement.value = '';
    if ( this.closeAfterSelction ) {
      this.inputSelect.close();
    }

  }

  ngOnDestroy(): void {
    this.inputSelect.close();
  }

  close(): void {
    this.inputSelect.close();
    this.items = of([]);
  }

  focusNextItem(): void {
    this.focusedIndex++;
  }

  focusPreviousItem(): void {
    this.focusedIndex--;
  }

  onSelectItemByKey(): void {
    this.items.subscribe((items => {
      if ( this.focusedIndex >= items.length || this.focusedIndex < 0 ) {
        return;
      }

      this.onSelect(items[this.focusedIndex]);
    }));
  }
}
