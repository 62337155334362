import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Input() control: AbstractControl | FormControl;
  @Input() placeholder: string;

  @Output() changedValue: EventEmitter<number> = new EventEmitter<number>();
  @Input() value: number;

  constructor() { }

  ngOnInit(): void {
  }

  onChange($event: any): void {
    const value = $event.target.value;
    this.changedValue.emit(+value);
  }
}
