import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './screens/login/security/login.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./screens/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./screens/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./screens/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [LoginGuard]
  },
  {
    path: '',
    loadChildren: () => import('./screens/terminal/terminal.module').then(m => m.TerminalModule),
    canActivate: [LoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
