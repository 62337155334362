<div *ngIf="!(hiddenSearch | async)" class="bg-glass fixed top-10 left-1/2 transform -translate-x-1/2 {{width}} mx-auto shadow-lg z-50">
  <div (click)="close()" class="cursor-pointer w-8 h-8 bg-white text-black shadow-lg rounded-full grid place-items-center z-50 absolute top-1/2 right-2 transform -translate-y-1/2">
    <i class="fal fa-times"></i>
  </div>

  <input #searchBox autofocus (keyup.enter)="onSelectItemByKey()" (keyup.arrowDown)="focusNextItem()" (keyup.arrowUp)="focusPreviousItem()" (input)="onSearch($event)" class="border bg-glass border-gray-500 rounded p-3 w-full bg-transparent text-white" type="text" placeholder="Search by name or code">

  <div class="list border border-gray-500 absolute z-50 top-full left-0 w-full shadow-lg" *ngIf="(items | async)?.length > 0">
    <ul class="bg-glass text-white">
      <li [ngClass]="{'font-bold border-t border-b border-gray-400': focusedIndex === i}" tabIndex="0" class="px-4 py-2 cursor-pointer" (click)="onSelect(item)" *ngFor="let item of (items | async); index as i">{{item.name || item.firstName || item.fullName || item.nameWithCode}}</li>
    </ul>
  </div>
</div>
