import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../screens/terminal/models/product.model';
import { Utils } from '../../utils';
import { appConfig } from '../../config/app.config';

@Pipe({
  name: 'getAutoGenCode'
})
export class GetAutoGenCodePipe implements PipeTransform {
  transform(value: number): string {
    const products: Product[] = Utils.getFromStore(appConfig.keys.products);
    if (products && products.length > 0) {
      return products.find(product => product.id === value).autoGenCode;
    }
    return '';
  }
}
