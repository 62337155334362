import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../screens/login/services/login.service';
import { Observable } from 'rxjs';
import { DecodedToken } from '../../../screens/login/models/decoded-token.model';
import {ProductService} from '../../../screens/terminal/services/product.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  now: Date = new Date();
  user: Observable<DecodedToken>;
  syncing: boolean;

  constructor(
    private login: LoginService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.now = new Date();
    }, 1000);

    this.user = this.login.getLoginInfoAsync();

    document.addEventListener('keyup', (e) => {
      if (e.key === '\\') {
        this.syncProducts();
      }
    });
  }

    syncProducts() {
    this.syncing = true;
    setTimeout(() => {
      this.syncing = false;
    }, 5000);

        this.productService.loadAndCacheAllProducts();
    }
}
