class ApiConfig {
  // baseUrl: string =  'https://localhost:44319';
  // fileUrl: string = 'https://localhost:44319';

  // For Bonik
   baseUrl: string = 'https://a00201100oapi.aitsidea.com';
   fileUrl: string = 'https://a00201100oapi.aitsidea.com';

  //baseUrl: string =  'https://bonikdemoapi.aitsidea.com';
  //fileUrl: string = 'https://bonikdemoapi.aitsidea.com';
}

export const apiConfig: ApiConfig = new ApiConfig();
