<div class="bg-glass border-r border-gray-500 h-screen text-white flex flex-col items-center justify-between">
  <div class="flex flex-col items-center">
    <a class="px-4 py-3" routerLink="/">
      <i class="fal fa-desktop" routerLinkActive="fas"></i>
    </a>

    <a class="px-4 py-3" routerLink="/orders">
      <i class="fal fa-box"></i>
    </a>
  </div>

  <div>
    <div *ngIf="info" class="text-center flex flex-col items-center py-4">
      <div class="w-8 h-8 overflow-hidden rounded-full">
        <img class="w-full h-full" [src]="info.PhotoUrl | image" alt="">
      </div>
      <p class="text-xs">{{info.userName}}</p>
    </div>

    <a class="px-4 py-3">
      <i (click)="logout()" class="fal fa-sign-out-alt"></i>
    </a>
  </div>
</div>
