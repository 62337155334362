import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {
  @Input() control: AbstractControl | FormControl;
  @Input() placeholder: string;
  @Input() readonly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
