<label class="text-white">Select {{Item}}</label>


<select *ngIf="control" (change)="onChange($event)" [formControl]="control" class=" appearance-none border border-gray-400 px-8 py-4 rounded w-full  text-white bg-glass bg-transparent text-gray-300">
   <option  value="0">Select {{Item}}</option>
   <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
</select>

<select *ngIf="!control" (change)="onChange($event)" class=" appearance-none border border-gray-400 px-8 py-4 rounded w-full  text-white bg-glass bg-transparent text-gray-300">
   <option  value="0">Select {{Item}}</option>
   <option [selected]="(selectedValue | async) === option.id" *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
</select>
