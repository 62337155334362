import { Pipe, PipeTransform } from '@angular/core';
import { apiConfig } from '../../config/api.config';

@Pipe({
  name: 'images'
})
export class ImagesPipe implements PipeTransform {

  transform(value: string): string {

    if ( !value ) {
      return 'https://via.placeholder.com/200';
    }

    return `${apiConfig.fileUrl}${value.split(',')[0]}`;
  }

}
