import { Component, OnInit } from '@angular/core';
import { LoginService } from './screens/login/services/login.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utils } from './utils';
import { appConfig } from './config/app.config';
import { SpotlightService } from './shared/widgets/spotlight/spotlight.service';
import { DecodedToken } from './screens/login/models/decoded-token.model';
import { ProductService } from './screens/terminal/services/product.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  info: DecodedToken;
  isSearchShown: BehaviorSubject<boolean>;

  constructor(
    private login: LoginService,
    private spotlightService: SpotlightService,
    private product: ProductService
  ) {
  }

  ngOnInit(): void {
    const token = Utils.getFromStore<string>(appConfig.keys.token);
    this.login.getInfoFromToken(token);
    this.info = this.login.getLoginInfo();
    this.isLoggedIn = this.login.getLoginStatusAsync();
  }
}
