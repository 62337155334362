import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { Utils } from '../../../utils';
import { appConfig } from '../../../config/app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private login: LoginService
  ) {
  }

  intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
    const isLoggedIn = this.login.getLoginStatus();
    const token = Utils.getFromStore<string>( appConfig.keys.token );

    if ( isLoggedIn && token ) {
      request = request.clone( {
        setHeaders: {
          Authorization: `Bearer ${ token }`
        }
      } );
    }

    return next.handle( request );
  }
}
