import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../../screens/login/services/login.service';
import { DecodedToken } from '../../../screens/login/models/decoded-token.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() info: DecodedToken;

  constructor(
    private login: LoginService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.login.logout();
  }
}
