import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from '../models/login-request.model';
import { apiConfig } from '../../../config/api.config';
import { appConfig } from '../../../config/app.config';
import { catchError, map } from 'rxjs/operators';
import { LoginResponse } from '../models/login-response.model';
import { Utils } from '../../../utils';
import { DecodedToken } from '../models/decoded-token.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { POSError } from '../security/POSError';
import {ProductService} from '../../terminal/services/product.service';

const helper = new JwtHelperService();

@Injectable( {
  providedIn: 'root'
} )
export class LoginService {
  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private loggedInInfo$: BehaviorSubject<DecodedToken> = new BehaviorSubject<DecodedToken>( null );

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private product: ProductService
  ) {
  }

  login( request: LoginRequest ): Observable<boolean> {
    this.spinner.show();
    return this.http.post( `${ apiConfig.baseUrl }/${ appConfig.endpoints.login }`, {
      ...request,
      grant_type: 'password',
      client_id: 'silence_spa',
      client_secret: '2a8c209b-bbce-4560-958d-a19792d60995'
    } )
      .pipe(
        map( ( res: LoginResponse ) => {
          if ( res.token ) {
            Utils.saveToStore<string>( appConfig.keys.token, res.token );
            this.getInfoFromToken( res.token );
          } else {
            this.setLoginStatus( false );
          }
          this.spinner.hide();
          return this.getLoginStatus();
        } ),
        catchError( (err: POSError) => {
          this.spinner.hide();
          return throwError( err );
        } )
      );
  }

  getInfoFromToken( token: string ): void {
    const info = helper.decodeToken<DecodedToken>( token );
    this.setLoginInfo( info );

    if ( info ) {
      this.setLoginStatus( true );
      this.product.loadAndCacheAllProducts();
    } else {
      this.setLoginStatus( false );
    }
  }


  setLoginStatus( status: boolean ): void {
    this.isLoggedIn$.next( status );
  }

  setLoginInfo( info: DecodedToken ): void {
    this.loggedInInfo$.next( info );
  }

  getLoginStatus(): boolean {
    return this.isLoggedIn$.value;
  }

  getLoginInfo(): DecodedToken {
    return this.loggedInInfo$.value;
  }

  getLoginStatusAsync(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  getLoginInfoAsync(): Observable<DecodedToken> {
    return this.loggedInInfo$.asObservable();
  }

  logout(): void {
    Utils.cleanStore();
    this.setLoginStatus( false );
    this.setLoginInfo( null );
    this.router.navigate([appConfig.routes.login]);
    location.reload();
  }

  getSaleCentersByEmployeeId(id: number, bcTypeId: number = 0): Observable<any[]> {
    return this.http.get<any[]>(`${apiConfig.baseUrl}/api/EmployeeBusinessCenterMap/GetBusinessCenterByEmployeeId/${id}/${bcTypeId}`)
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      );
  }

  getEmployeeByUserId(): Observable<any> {
    return this.http.get(`${apiConfig.baseUrl}/api/Employee/GetEmployeeByUserId/${this.getLoginInfo().userId}`)
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      );
  }
}
