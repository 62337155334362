export class DataTablePagination<TSearchVm, TSearchResult> {
  id: number = 0;
  userId: number = 0;
  serialNo: number = 0;
  canCreate: boolean = false;
  canUpdate: boolean = false;
  canView: boolean = false;
  canDelete: boolean = false;
  sortField: string = '';
  sortOrder: string = '';
  filter: string = '';
  totalItemsCount: number = 0;
  currentPageNo: number = 0;
  itemsPerPage: number = 0;
  itemsPerPageOptions: number[] = [];
  itemsPerPageReportOptions: number[] = [];
  startPoint: number = 0;
  endPoint: number = 0;
  search: {
    value: string;
    regex: string;
  } = {
    value: '',
    regex: ''
  };
  column: {
    order: number;
    regex: string;
  };
  dataList: TSearchResult[] = [];
  searchModel: TSearchVm;
  userOrganizationIds: number[];
  organizationId: number;
  lineDraw: number | null;
  start: number | null;
  length: number | null;
  order: string;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  data: TSearchResult[];
}
