import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeFalsy'
})
export class RemoveFalsyPipe implements PipeTransform {

  transform(value: string, token: string): string {
    if (!value) {
      return '';
    }

    return value.split( token ).filter( c => c.length > 0 ).join( token );
  }

}
