import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './widgets/navbar/navbar.component';
import { InputTextComponent } from './widgets/input-text/input-text.component';
import { InputNumberComponent } from './widgets/input-number/input-number.component';
import { InputPasswordComponent } from './widgets/input-password/input-password.component';
import { SpotlightComponent } from './widgets/spotlight/spotlight.component';
import { ButtonComponent } from './widgets/button/button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolbarComponent } from './widgets/toolbar/toolbar.component';
import { CalculatePipe } from './pipes/calculate-price.pipe';
import { HttpClient } from '@angular/common/http';
import {DropdownComponent} from './widgets/dropdown/dropdown.component';
import { RemoveFalsyPipe } from './pipes/remove-falsy.pipe';
import { ImagePipe } from './pipes/image.pipe';
import { ImagesPipe } from './pipes/images.pipe';
import { GetAutoGenCodePipe } from './pipes/get-auto-gen-code.pipe';
import { MakeAbsolutePipe } from './pipes/make-absolute.pipe';
import { TakeFirstPipe } from './pipes/take-first.pipe';
import { MakeWordShorterPipe } from './pipes/make-word-shorter.pipe';



@NgModule( {
  declarations: [
    NavbarComponent,
    InputTextComponent,
    InputNumberComponent,
    InputPasswordComponent,
    SpotlightComponent,
    ButtonComponent,
    ToolbarComponent,
    CalculatePipe,
    DropdownComponent,
    RemoveFalsyPipe,
    ImagePipe,
    ImagesPipe,
    GetAutoGenCodePipe,
    MakeAbsolutePipe,
    TakeFirstPipe,
    MakeWordShorterPipe
  ],
  exports: [
    NavbarComponent,
    InputTextComponent,
    InputPasswordComponent,
    ButtonComponent,
    SpotlightComponent,
    ToolbarComponent,
    CalculatePipe,
    InputNumberComponent,
    DropdownComponent,
    RemoveFalsyPipe,
    ImagesPipe,
    ImagePipe,
    GetAutoGenCodePipe,
    TakeFirstPipe,
    MakeAbsolutePipe,
    MakeWordShorterPipe
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        RouterModule,
    ],
})
export class SharedModule { }
