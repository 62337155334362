import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { POSError } from './POSError';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private login: LoginService,
    private alert: ToastrService
  ) {}

  intercept( request: HttpRequest<POSError>, next: HttpHandler): Observable<HttpEvent<POSError>> {
    return next.handle( request )
      .pipe(
        catchError( ( err ) => {
          if ( err.status === 401 ) {
            console.log( 'intercepted an error, and logging out' );
            console.log( err );
            this.login.logout();
            location.reload( true );
          }

          const error: POSError = new POSError();

          error.title = err.statusText;
          error.code = +err.status;

          if ( err.error ) {
            error.text = err.error?.PublicMessage || err.error?.Message || err.error?.message || err.error?.error_description;
          } else {
            error.text = err.message;
          }

          return throwError( error );
        } )
      );
  }
}
