import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { DataTablePagination } from '../../models/datatable-pagination.model';
import { apiConfig } from '../../../config/api.config';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { POSError } from '../../../screens/login/security/POSError';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConfig } from '../../../config/app.config';
import { ProductService } from '../../../screens/terminal/services/product.service';

@Injectable( {
  providedIn: 'root'
} )
export class SpotlightService {
  isHidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private alert: ToastrService,
    private spinner: NgxSpinnerService,
    private product: ProductService
  ) {
  }

  search<Type>( key: string, endpoint: string ): Observable<any[]> {
    const pagination = new DataTablePagination<Type, Type>();

    pagination.itemsPerPage = 5;
    pagination.search.value = key || '';

    if ( endpoint === appConfig.endpoints.products ) {
      return of(this.product.searchProducts(key, pagination.itemsPerPage));
    }

    // this.spinner.show();
    return this.http.post<DataTablePagination<Type, Type>>( `${ apiConfig.baseUrl }/${ endpoint }`, pagination ).pipe(
      map( ( res: DataTablePagination<Type, Type> ) => {
        // this.spinner.hide();
        return res.dataList || [];
      } ),
      catchError( (err: POSError) => {
        // this.spinner.hide();
        this.alert.error(err.text, err.title);
        return throwError( err );
      } )
    );
  }

  listenKeyPress( el: ElementRef ): void {
    window.addEventListener( 'keyup', ( e ) => {
      if ( e.altKey && (e.code === 'KeyS' || e.keyCode === 83) ) {
        this.open();
      }

      if ( e.code === 'Escape' ) {
        this.close();
      }

    } );
  }

  open(): void {
    this.isHidden.next(false);
  }

  close(): void {
    this.isHidden.next(true);
  }
}
