import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetail } from '../../screens/terminal/models/order-detail.model';

@Pipe({
  name: 'calculate'
})
export class CalculatePipe implements PipeTransform {

  transform(value: OrderDetail, isPoint: boolean = false): number {
    const toWhomToCalculate = isPoint ? value.loyaltyPoint : value.salePrice;
    return toWhomToCalculate * value.qty;
  }

}
