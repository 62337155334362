import { apiConfig } from './api.config';

export const appConfig = {
  name: 'B-POS',
  api: apiConfig,
  version: '1.0.0',
  routes: {
    home: '/',
    terminal: '/',
    payment: '/payment',
    login: '/login'
  },
  endpoints: {
    products: 'api/product/search',
    customers: 'api/customer/search',
    newCustomer: 'api/customer',
    orders: 'api/sale/search',
    orderDetail: 'api/sale',
    payment: 'api/sale/saleAndPayment',
    paymentWithConfirm: 'api/sale/PostWithConfirm',
    paymentWithApprove: 'api/sale/PostWithApproved',
    login: 'connect/token',
    organization: 'api/organization',
    statusChange: 'api/sale/SaleUpdateStatus',
    sale: 'api/sale'
  },
  keys: {
    orderedProducts: 'ordered_products_in_this_session',
    token: 'access_token',
    order: 'current_order',
    lastOrder: 'last_order',
    saleCenters: 'sale_centers',
    products: 'products'
  }
};
