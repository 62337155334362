import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'takeFirst'
})
export class TakeFirstPipe implements PipeTransform {

  transform(value: string, token: string): string {
    return value.split(token, 1).join(token);
  }


}
