import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() control: AbstractControl | FormControl;
  @Input() Item: string;

  @Output() selectedValue: EventEmitter<number> = new EventEmitter<number>();

  @Output() selectedItemObj: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (!this.control && this.options?.length > 0) {
      this.selectedValue.emit(this.options[0]);
    }
  }
  onChange($event: any): void {
    this.selectedValue.emit($event.target.value);
    const item = this.options.find(c => c.id === +$event.target.value);
    this.selectedItemObj.emit(item);
  }
}
