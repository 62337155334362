import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'makeWordShorter'
} )
export class MakeWordShorterPipe implements PipeTransform {

  transform( value: string, count: number = 4 ): string {
    if ( value.split( ' ' ).length === 1 ) {
      return value;
    }
    return `${value.split(' ')[0]} ${this.takeAndMake( value, count )}`;
  }

  private takeAndMake( value: string, count: number ): string {
    const wordsWithoutFirst: string[] = value.split( ' ' ).splice( 1 );
    const splicedWords: string[] = wordsWithoutFirst.map( word => word.split( '' ).splice( 0, count ).join( '' ) );
    return splicedWords.join( ' ' );
  }
}
