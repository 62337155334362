<div class="p-2 flex justify-between items-center text-gray-300 font-bold">
  <div class="flex items-center space-x-2">
    <div class="w-8 h-8 rounded overflow-hidden">
      <img class="w-full h-full" [src]="(user | async)?.PhotoUrl | image" alt="">
    </div>
    <div>
      <h3 class="tracking-wider">{{(user | async)?.organizationName}}</h3>
    </div>
  </div>
  <h3>
    <i class="fal fa-watch-fitness mr-2"></i>
    {{now | date: 'dd-MMM-yyy hh:mm:ss a'}}
    <i class="fal fa-sync ml-2" [ngClass]="{'animate-spin': syncing}" (click)="syncProducts()"></i>
  </h3>
</div>
